import { observer } from "mobx-react-lite";
import { CSSProperties, useCallback } from "react";
import { store } from "../../store/store";
import { Flex, VFlex } from "../styled/Flex";
import MailSVG from "../SVG/MailSVG";
import PhoneSVG from "../SVG/PhoneSVG";
import WhatsappSVG from "../SVG/WhatsappSVG";
import { Body } from "../Typography";
interface MainTabPageProps {
  style?: CSSProperties;
}

const MainTabPage = observer((props: MainTabPageProps) => {
  const handleAddPointClick = useCallback((evt) => {
    const cb = (obj) => {
      const id = Math.random();
      store.clickTextDict[id] = {
        value: 5,
        position: {
          left: obj.clientX,
          top: obj.clientY,
        },
      };
      store.points += 5;
    };
    Object.values(evt.touches).forEach(cb);
  }, []);
  return (
    <VFlex style={{ flex: 1, alignItems: "stretch" }}>
      {/* Top Part */}
      <Flex
        style={{
          justifyContent: "space-between",
          padding: "14px 12px",
        }}
      >
        <Flex style={{ alignItems: "flex-start", gap: 6 }}>
          <Flex style={{ backgroundColor: "#91ff1e", padding: "2px 3px" }}>
            <Body style={{ textShadow: "0px 0px 5px #000" }}>4</Body>
          </Flex>
          <Flex
            style={{
              backgroundColor: "#515151",
              width: 140,
              height: 20,
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 1,
                left: 1,
                bottom: 1,
                width: "50%",
                backgroundColor: "#ffc62a",
              }}
            />
            <Body
              style={{
                textShadow: "0px 0px 5px #000",
                zIndex: 1,
                textAlign: "center",
                width: "100%",
                fontSize: 13,
              }}
            >
              1000 / 2000
            </Body>
          </Flex>
        </Flex>
        <Flex
          style={{
            borderRadius: 100,
            border: "1px solid #3c3c3d",
            height: 30,
            width: 180,
            alignItems: "center",
            padding: "0px 12px",
            justifyContent: "space-between",
          }}
        >
          <PhoneSVG fill="#ffbf3d" />
          <Body>1750 / 1750</Body>
          <WhatsappSVG />
        </Flex>
      </Flex>
      <Flex style={{ alignSelf: "center", gap: 12 }}>
        {[
          {
            Icon: MailSVG,
            title: "Game",
            onClick: () => {},
          },
          {
            Icon: MailSVG,
            title: "Mystery",
            onClick: () => {},
          },
          {
            Icon: MailSVG,
            title: "Roun",
          },
          {
            Icon: MailSVG,
            title: "CHECK-IN",
            onClick: () => {},
          },
          {
            Icon: MailSVG,
            title: "",
            onClick: () => {},
          },
        ].map(({ Icon, title, onClick }) => {
          return (
            <VFlex style={{ alignItems: "center", gap: 4 }}>
              <VFlex
                onClick={onClick}
                style={{
                  padding: 4,
                  border: "2px solid #ffc92a",
                  borderRadius: 12,
                }}
              >
                <Icon
                  style={{
                    width: 24,
                    height: 24,
                  }}
                  fill="#ffc92a"
                />
              </VFlex>
              <Body style={{ fontSize: 9 }}>{title}</Body>
            </VFlex>
          );
        })}
      </Flex>
      {/* Main Box */}
      <VFlex
        style={{
          flex: 1,
          marginLeft: -2,
          marginRight: -2,
          marginBottom: -8,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          border: "2px solid #8af0f1",
          marginTop: 4,
          boxShadow: "0px 0px 24px #8af0f1",
          position: "relative",
        }}
        onTouchStart={handleAddPointClick}
        // onMouseDown={handleAddPointClick}
      >
        <Flex
          style={{
            marginTop: 12,
          }}
        >
          {[
            {
              title: "EARN PER ZAP",
              value: 5,
              Icon: WhatsappSVG,
            },
            {
              title: "PROFIT PER HOUR",
              value: 5,
              Icon: WhatsappSVG,
            },
            {
              title: "$NANO",
              value: 5,
              Icon: WhatsappSVG,
            },
          ].map(({ title, value, Icon }, idx, arr) => {
            const isNotLast = idx < arr.length - 1;
            return (
              <VFlex
                style={{
                  alignItems: "center",
                  flex: 1,
                  borderRight: isNotLast ? "1px solid white" : "none",
                }}
                key={title}
              >
                <Body
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                    WebkitTextStrokeWidth: 1,
                    WebkitTextStrokeColor: "grey",
                  }}
                >
                  {title}
                </Body>
                <Flex style={{ alignItems: "center" }}>
                  <Icon />
                  <Body
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      textShadow: "0px 0px 1px grey",
                      WebkitTextStrokeColor: "grey",
                    }}
                  >
                    {value}
                  </Body>
                </Flex>
              </VFlex>
            );
          })}
        </Flex>
        <Flex
          style={{
            alignSelf: "center",
            alignItems: "center",
            gap: 3,
            marginTop: 10,
          }}
        >
          <WhatsappSVG style={{ width: 32, height: 32 }} />
          <Body
            style={{
              textShadow: "0px 0px 1px grey",
              WebkitTextStrokeColor: "grey",
              fontSize: 24,
            }}
          >
            {store.points}
          </Body>
        </Flex>
        <VFlex
          style={{
            position: "absolute",
            bottom: 24,
            right: 12,
            width: 64,
            height: 64,
            border: "2px solid #f5c12a",
            borderRadius: 12,
          }}
        ></VFlex>
      </VFlex>
    </VFlex>
  );
});

export default MainTabPage;
