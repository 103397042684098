export function clickLink(link: string, name: string) {
  const a = document.createElement("a");
  a.href = link;
  a.download = `${name}.vcf`;
  document.body.appendChild(a);
  a.click();

  // Clean up the DOM
  document.body.removeChild(a);
}

export function copyToClipboard(t: string) {
  return navigator.clipboard.writeText(t);
}

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
