import styled from "styled-components";

export const StyledBody = styled.div`
  font-family: "Ethnocentric";
`;

export const Body = styled.div`
  font-family: Poppins;
  font-size: 16px;
`;

export const Headline3 = styled(Body)`
  font-weight: bold;
  font-size: 24px;
`;

export const SubTitle = styled(Body)`
  font-size: 12px;
  opacity: 0.5;
  font-weight: 300;
`;
