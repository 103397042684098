import { SVGProps } from ".";
const WhatsappSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M3 21L4.27129 16.3778C3.48681 15.0247 3.0746 13.491 3.07536 11.9183C3.07762 7.00125 7.09797 3 12.0377 3C14.4348 3.00075 16.685 3.93 18.3775 5.616C20.0693 7.302 21.0008 9.543 21 11.9265C20.9977 16.8443 16.9774 20.8455 12.0377 20.8455C10.5381 20.8448 9.06029 20.4705 7.75132 19.7595L3 21ZM7.97136 18.1448C9.23436 18.891 10.4401 19.338 12.0347 19.3387C16.1402 19.3387 19.4846 16.0133 19.4868 11.925C19.4883 7.8285 16.1598 4.5075 12.0407 4.506C7.93218 4.506 4.59005 7.8315 4.58855 11.919C4.58779 13.5878 5.07913 14.8372 5.9043 16.1445L5.15147 18.8805L7.97136 18.1448ZM16.5524 14.0468C16.4966 13.9537 16.3474 13.8982 16.1228 13.7865C15.899 13.6748 14.798 13.1355 14.5923 13.0612C14.3873 12.987 14.2381 12.9495 14.0882 13.173C13.939 13.3958 13.5094 13.8982 13.379 14.0468C13.2487 14.1953 13.1176 14.214 12.8937 14.1023C12.6699 13.9905 11.948 13.7557 11.0927 12.996C10.4273 12.405 9.97739 11.6753 9.84702 11.4517C9.71665 11.229 9.83346 11.1082 9.94499 10.9973C10.046 10.8975 10.1688 10.737 10.2811 10.6065C10.3949 10.4775 10.4318 10.3845 10.5072 10.2353C10.5818 10.0868 10.5448 9.95625 10.4883 9.8445C10.4318 9.7335 9.98417 8.63625 9.79804 8.19C9.61567 7.75575 9.43105 7.81425 9.2939 7.8075L8.86436 7.8C8.71515 7.8 8.47249 7.8555 8.26752 8.079C8.06255 8.3025 7.4838 8.841 7.4838 9.93825C7.4838 11.0355 8.28636 12.0953 8.39789 12.2437C8.51017 12.3923 9.97664 14.6438 12.2231 15.609C12.7573 15.8385 13.1748 15.9757 13.4996 16.0785C14.0362 16.248 14.5245 16.224 14.9103 16.167C15.3406 16.1032 16.2351 15.6277 16.422 15.1073C16.6089 14.586 16.6089 14.1398 16.5524 14.0468Z"
        fill="white"
      />
    </svg>
  );
};

export default WhatsappSVG;
