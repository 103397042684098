import { observer } from "mobx-react-lite";
import { CSSProperties, HtmlHTMLAttributes } from "react";
interface XSVGProps extends HtmlHTMLAttributes<HTMLOrSVGElement> {
  style?: CSSProperties;
  fill?: string;
  size?: number;
}

const XSVG = observer((props: XSVGProps) => {
  const { fill, size, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? size : "19"}
      height={size ? size - 2 : "17"}
      viewBox="0 0 19 17"
      fill="none"
      {...p}
    >
      <path
        d="M14.843 0H17.6032L11.5731 6.89195L18.667 16.2703H13.1125L8.76211 10.5824L3.78422 16.2703H1.02244L7.47217 8.89862L0.666992 0H6.36245L10.2949 5.199L14.843 0ZM13.8743 14.6183H15.4037L5.53141 1.56529H3.89019L13.8743 14.6183Z"
        fill={fill || "white"}
      />
    </svg>
  );
});

export default XSVG;
