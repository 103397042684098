import { makeAutoObservable } from "mobx";
import { WIDTH_THRESHOLD } from "../constants";
import { ETabs } from "../types/types";

class Store {
  screenSize = {
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientHeight,
  };

  setScreenSize(s: { width: number; height: number }) {
    this.screenSize = s;
  }

  get isDesktop() {
    return this.screenSize.width > WIDTH_THRESHOLD;
  }

  currentTab: ETabs = ETabs.ZAP;

  points = 26503;

  clickTextDict: Record<
    string,
    {
      value: number;
      position: {
        left: number;
        top: number;
      };
    }
  > = {};

  constructor() {
    makeAutoObservable(this);
  }
}

export const store = new Store();
