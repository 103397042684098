import { SVGProps } from ".";
const PhoneSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...p}
    >
      <path
        d="M14.5714 16.5C14.5714 16.7387 14.4811 16.9676 14.3204 17.1364C14.1596 17.3052 13.9416 17.4 13.7143 17.4H10.2857C10.0584 17.4 9.84037 17.3052 9.67962 17.1364C9.51888 16.9676 9.42857 16.7387 9.42857 16.5C9.42857 16.2613 9.51888 16.0324 9.67962 15.8636C9.84037 15.6948 10.0584 15.6 10.2857 15.6H13.7143C13.9416 15.6 14.1596 15.6948 14.3204 15.8636C14.4811 16.0324 14.5714 16.2613 14.5714 16.5ZM18 5.7V18.3C18 19.0161 17.7291 19.7028 17.2468 20.2092C16.7646 20.7155 16.1106 21 15.4286 21H8.57143C7.88944 21 7.23539 20.7155 6.75315 20.2092C6.27092 19.7028 6 19.0161 6 18.3V5.7C6 4.98392 6.27092 4.29716 6.75315 3.79081C7.23539 3.28446 7.88944 3 8.57143 3H15.4286C16.1106 3 16.7646 3.28446 17.2468 3.79081C17.7291 4.29716 18 4.98392 18 5.7ZM16.2857 5.7C16.2857 5.46131 16.1954 5.23239 16.0347 5.0636C15.8739 4.89482 15.6559 4.8 15.4286 4.8H8.57143C8.3441 4.8 8.12608 4.89482 7.96534 5.0636C7.80459 5.23239 7.71429 5.46131 7.71429 5.7V18.3C7.71429 18.5387 7.80459 18.7676 7.96534 18.9364C8.12608 19.1052 8.3441 19.2 8.57143 19.2H15.4286C15.6559 19.2 15.8739 19.1052 16.0347 18.9364C16.1954 18.7676 16.2857 18.5387 16.2857 18.3V5.7Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default PhoneSVG;
