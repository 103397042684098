import { useTonConnectModal, useTonWallet } from "@tonconnect/ui-react";
import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { MainButton } from "../MainButton";
import { VFlex } from "../styled/Flex";
interface AirdropTabPageProps {
  style?: CSSProperties;
}

const AirdropTabPage = observer((props: AirdropTabPageProps) => {
  const { state, open, close } = useTonConnectModal();
  const wallet = useTonWallet();
  return (
    <VFlex style={{ flex: 1, alignItems: "stretch" }}>
      <div>Modal state: {state?.status}</div>
      {wallet ? (
        <div>
          <span>Connected wallet: {wallet.account.address}</span>
          <span>Device: {wallet.device.appName}</span>
        </div>
      ) : (
        <MainButton
          onClick={() => {
            open();
          }}
        >
          Connect Wallet
        </MainButton>
      )}
    </VFlex>
  );
});

export default AirdropTabPage;
