import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { store } from "../store/store";
import ClickPopupText from "./ClickPopupText";
interface ClickPopupTextContainerProps {
  style?: CSSProperties;
}

const ClickPopupTextContainer = observer(
  (props: ClickPopupTextContainerProps) => {
    return (
      <>
        {Object.entries(store.clickTextDict).map(([k, { value, position }]) => {
          return (
            <ClickPopupText k={k} value={value} position={position} key={k} />
          );
        })}
      </>
    );
  },
);

export default ClickPopupTextContainer;
