import { createGlobalStyle } from "styled-components";

const GlobalCSS = createGlobalStyle`

.simple-btn {
  opacity: 1;
  outline: 0;
  transition: all 0.2s;
  
  &:not(.disabled) {
    cursor: pointer;
  }
  &:hover:not(.disabled):not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled):not(:active) {
    opacity: 0.8;
  }

  &:active:not(.disabled):not(:disabled):not(.pancake-button--disabled):not(.pancake-button--disabled) {
    opacity: 0.85;
  }
  &a:hover {
    color: unset;
  }
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

`;

export default GlobalCSS;
