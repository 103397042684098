import XSVG from "./components/SVG/XSVG";
import { ETabs } from "./types/types";

export const ENV = (process.env.REACT_APP_ENV || "").trim();

export const IS_PROD = ENV === "prod";

export const WIDTH_SHORTEN_THRESHOLD = 1024;
export const WIDTH_THRESHOLD = 768;
export const MAX_DESKTOP_WIDTH = 1100;

export const getLtMediaQuery = (size: number | string) =>
  `@media only screen and (max-width: ${size}px)`;

export const XS_WIDTH = 600;
export const SM_WIDTH = 960;
export const MD_WIDTH = 1280;
export const LG_WIDTH = 1920;

export const MediaQueryMobileOnly = getLtMediaQuery(WIDTH_THRESHOLD);
export const MediaQueryShortenOnly = getLtMediaQuery(
  WIDTH_SHORTEN_THRESHOLD + 1,
);

export const MediaQueryXSOnly = getLtMediaQuery(XS_WIDTH);
export const MediaQuerySMOnly = getLtMediaQuery(SM_WIDTH);
export const MediaQueryMDOnly = getLtMediaQuery(MD_WIDTH);
export const MediaQueryLGOnly = getLtMediaQuery(LG_WIDTH);

export const COLORS = {
  grey0: "#FFFFFF50",
  grey1: "#3A4142",
  grey2: "#2E3435",
  grey3: "#1A1E1F",
  dark: "#0E0C34",
  purple1: "#D585FF",
  purple2: "#BF77E6",
  purple3: "#9F63BF",
  skyblue: "#64CCF7",
  blue1: "#a4d9f6",
  blue2: "#80c9f2",
  blue3: "#69b7e3",
  green1: "#8BFCE5",
  green2: "#7DE3CF",
  green3: "#68BDAC",
  pink: "#FCC5B1",
  orange: "#FCD4A4",
  alert: "#DE3146",
  darkBlack: "#050505",
  kubzbg: "#4a7aa7",
  brightGreen: "#26dd6b",
  main: "white",
  highlight: "#fdb720",
  shadedHighlight: "#91651d",
  highlight2: "rgb(239, 182, 21)",
  secondary: "#b5b8fc",
  primary2: "#FCC787",
  purpleV2: "#AF6AFE",

  white100: "#FFFFFF",
  white80: "#FFFFFFCC",
  white60: "#FFFFFF99",
  white40: "#FFFFFF66",
  white15: "#FFFFFF26",
  white10: "#FFFFFF1A",
  white5: "#FFFFFF0D",

  yellow100: "#FFC671",
  yellow60: "#FFC67199",
  yellow40: "#FFC67166",
  yellow15: "#FFC67126",
  yellow10: "#FFC6711A",
  yellow5: "#FFC6710D",

  super100: "#FFBF43",
};

export const TabData = {
  [ETabs.ZAP]: {
    icon: XSVG,
    title: "ZAP",
  },
  [ETabs.UPGRADE]: {
    icon: XSVG,
    title: "UPGRADE",
  },
  [ETabs.WEAPON]: {
    icon: XSVG,
    title: "WEAPON",
  },
  [ETabs.INVITE]: {
    icon: XSVG,
    title: "INVITE",
  },
  [ETabs.EARN]: {
    icon: XSVG,
    title: "EARN",
  },
  [ETabs.AIRDROP]: {
    icon: XSVG,
    title: "AIRDROP",
  },
};
