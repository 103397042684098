import initReactFastclick from "react-fastclick";

import { TonConnectUIProvider } from "@tonconnect/ui-react";
import classNames from "classnames";
import { configure } from "mobx";
import { observer } from "mobx-react";
import { useEffect } from "react";

import "./App.css";
import BottomTabBar from "./components/BottomTabBar";
import ClickPopupTextContainer from "./components/ClickPopupTextContainer";
import { VFlex } from "./components/styled/Flex";
import GlobalCSS from "./components/styled/GlobalStyle";
import AirdropTabPage from "./components/tab-pages/AirdropTabPage";
import MainTabPage from "./components/tab-pages/MainTabPage";
import { store } from "./store/store";
import { ETabs } from "./types/types";

initReactFastclick();

configure({
  enforceActions: "never",
});

function App() {
  useEffect(() => {
    const resizeListener = () => {
      requestAnimationFrame(() => {
        store.setScreenSize({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
    };
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);
  return (
    <TonConnectUIProvider manifestUrl="https://wellness-clicker-dev.pages.dev/tonconnect-manifest.json">
      {/* Your app */}
      <div
        className={classNames("flex flex-col items-stretch h-full ")}
        style={{ overflow: "hidden", maxWidth: "100vw", maxHeight: "100vh" }}
      >
        <GlobalCSS />
        <VFlex className="flex-1">
          {(() => {
            switch (store.currentTab) {
              case ETabs.ZAP:
              case ETabs.UPGRADE:
                return <MainTabPage />;
              case ETabs.WEAPON:
                return <div>weapon</div>;
              case ETabs.INVITE:
                return <div>invite</div>;
              case ETabs.EARN:
                return <div>earn</div>;
              case ETabs.AIRDROP:
                return <AirdropTabPage />;
            }
          })()}
        </VFlex>
        <BottomTabBar />
        <ClickPopupTextContainer />
      </div>
    </TonConnectUIProvider>
  );
}

export default observer(App);
