import { observer } from "mobx-react-lite";
import { CSSProperties } from "react";
import { TabData } from "../constants";
import { store } from "../store/store";
import { ETabs } from "../types/types";
import { Flex, VFlex } from "./styled/Flex";
import { Body } from "./Typography";
interface BottomTabBarProps {
  style?: CSSProperties;
}

const BottomTabBar = observer((props: BottomTabBarProps) => {
  return (
    <Flex
      style={{
        width: "100%",
        maxWidth: "100%",
        height: 84,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        backgroundColor: "#05071a",
      }}
    >
      {Object.entries(TabData).map(([k, { icon: Icon, title }]) => {
        const tabKey = parseInt(k, 10) as ETabs.AIRDROP;
        const color = tabKey === store.currentTab ? "white" : "#8399c1";
        return (
          <VFlex
            style={{
              transition: "all 0.3s",
              gap: 3,
              alignItems: "center",
              alignSelf: "stretch",
              justifyContent: "center",
              flex: 1,
            }}
            key={k}
            onClick={() => {
              store.currentTab = tabKey;
            }}
          >
            <Icon style={{ transition: "all 0.3s" }} fill={color} />
            <Body
              style={{
                transition: "all 0.3s",
                fontSize: 11,
                color,
                fontWeight: "bold",
              }}
            >
              {title}
            </Body>
          </VFlex>
        );
      })}
    </Flex>
  );
});

export default BottomTabBar;
