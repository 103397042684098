import { SVGProps } from ".";
const MailSVG = (props: SVGProps) => {
  const { fill, ...p } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...p}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.2 7.19999C3.2 6.75817 3.55817 6.39999 4 6.39999H20C20.4418 6.39999 20.8 6.75817 20.8 7.19999V16.8C20.8 18.1255 19.7255 19.2 18.4 19.2H5.6C4.27452 19.2 3.2 18.1255 3.2 16.8V7.19999ZM4.8 8.73652V16.8C4.8 17.2418 5.15817 17.6 5.6 17.6H18.4C18.8418 17.6 19.2 17.2418 19.2 16.8V8.73652L12.4588 13.4554C12.1833 13.6482 11.8167 13.6482 11.5412 13.4554L4.8 8.73652ZM17.4621 7.99999H6.53789L12 11.8235L17.4621 7.99999Z"
        fill={fill || "white"}
      />
    </svg>
  );
};

export default MailSVG;
